<template>
	<div class="">
		<!--本页切换列表-->
		<div>
			<div class="tableCon">
				<div class="tableConTable">
					<div class="tableConTop">
						<el-row>
							<el-col :span="5" class="tableConTopLeft">
								<h3>
									<span class="tct_tit">C-Lodop <span>{{$t('i18nn_736c4f14b7c228e8')}}</span></span>
								
								</h3>
							</el-col>
							<el-col :span="19" class="tableConTopRig">
								
							</el-col>
						</el-row>
					</div>
					
					<div class="downList">
						<!-- <ul> -->
							<!-- <li>
								<div v-if="hasLODOP">
									<span>Lodop version：{{LODOP.VERSION}}</span>
									<span style="font-size: 14px;color:red;">（请安装不小于6.2.2.6的版本）</span>
								</div>
							</li> -->
							<!-- <li> -->
							<el-card>
								<div slot="header">
								  <span style="font-size: 20px;font-weight: bold;">{{$t('i18nn_869467a93c7dd4a1')}}</span>
								</div>
								
								<div class="title" v-if="CLODOP && CLODOP.CVERSION">
									<span>C-Lodop Version</span>
									<br />
									<span><span>{{$t('i18nn_fa642f1b3c8159cd')}}</span>：{{CLODOP.CVERSION}}</span>
									<span style="font-size: 14px;color:red;">（<span>{{$t('i18nn_8d565f4ef805f787')}}</span>4.1.2.7<span>{{$t('i18nn_a902ce087be9d96c')}}</span>）</span>
								</div>
								
								<div >
									<div class="title">
										<el-button type="primary"><a href='/c-lodop/CLodop_Setup_for_Win32NT.exe' target='_self' style="color:#fff;"><span>{{$t('i18nn_245883c80f181c4a')}}</span>CLodop {{version}}</a></el-button>
										
									</div>
									<div class="title">
										<span><span>{{$t('i18nn_6a6a43ebb138860a')}}</span>：{{version}}</span>
									</div>
									<ul class="list-style-1">
										<li>1、目前仅支持<strong>Windows</strong>{{$t('i18nn_f302ed3011ec4e3a')}}<strong>Linux</strong>系统，Linux系统请至<a href="http://www.c-lodop.com/" target="_blank">{{$t('i18nn_d88d2c774b992fff')}}</a>下载。</li>
										<li>2、点击<a href='/c-lodop/CLodop_Setup_for_Win32NT.exe' target='_self'>下载CLodop {{version}}</a>执行安装或升级,安装后请刷新页面或重新进入。</li>
										
										<li>3、下载后，安装CLodop_Setup_for_Win32NT.exe。安装时一直点下一步即可。</li>
										<li>4、成功安装后，刷新浏览器。</li>
										<li>5、如果被浏览器拦截。请查看网址栏最右侧，点击允许加载后刷新浏览器。如下图所示：</li>
										<li>
											<img src="../../../assets/images/clodopExam/ex_lanjiejs.png" width="1065" height="41" />
										</li>
										<li>6、其他情况请联系客服或管理员。</li>
									</ul>
								</div>
							</el-card>
								
							<!-- </li> -->
						<!-- </ul> -->
						<el-card>
							<div slot="header">
							  <span style="font-size: 20px;font-weight: bold;">{{$t('i18nn_9b6fc3dc177f566c')}}</span>
							</div>
							<ul class="list-style-2">
								<li class="title">
									<el-button type="warning" @click="PRINT_PDF_TEST"><span>{{$t('i18nn_886072e393710563')}}</span>PDF<span>{{$t('i18nn_8abb75d7c36c9668')}}</span></el-button>
									<span>
										若测试页打印异常，则打印控件 CLODOP可能异常。请按上方提示检查。
									</span>
								</li>
								<!-- <li>{{$t('i18nn_bb346b646af55bd8')}}<a href='/c-lodop/install_lodop32.exe' target='_self'>install_lodop32.exe</a>执行安装或升级,安装后请刷新页面或重新进入。</li> -->
								<!-- <li>{{$t('i18nn_bb346b646af55bd8')}}<a href='/c-lodop/install_lodop64.exe' target='_self'>install_lodop64.exe</a>执行安装或升级,安装后请刷新页面或重新进入。</li> -->
								<li>安装更新方式：点击<a href='/c-lodop/CLodop_Setup_for_Win32NT.exe' target='_self'>CLodop {{version}}</a>执行安装或升级,安装后请刷新页面或重新进入。</li>
								
								<li>Chrome：(如果此前正常，仅因浏览器升级或重安装而出问题，需重新执行以上安装）</li>
								<li>FireFox：（注意：如曾安装过Lodop旧版附件npActiveXPLugin,请在【工具】->【附加组件】->【扩展】中先卸它）</li>
								<li>其他：（若此前已安装过，可<a href='CLodop.protocol:setup' target='_self'>{{$t('i18nn_07672c898e0f679f')}}</a>）</li>
								<li>LODOP官网：<a href="http://www.c-lodop.com/" target="_blank">http://www.c-lodop.com/</a></li>
								<li>LODOP本地预览配置页:<a href="http://localhost:8000" target="_blank">(http://localhost:8000)</a></li>
								<li><el-button @click="PRINT_DESIGN">{{$t('i18nn_6b1a265012492058')}}</el-button></li>
							</ul>
						</el-card>
						<!-- <br><font color='#FF00FF'>打印控件未安装!点击这里<a href='/c-lodop/install_lodop32.exe' target='_self'>{{$t('i18nn_953f4232ebdf7eb2')}}</a>,安装后请刷新页面或重新进入。</font>";
						<br><font color='#FF00FF'>打印控件需要升级!点击这里<a href='/c-lodop/install_lodop32.exe' target='_self'>{{$t('i18nn_7fd5cb82192c32c2')}}</a>,升级后请重新进入。</font>";
						<br><font color='#FF00FF'>打印控件未安装!点击这里<a href='/c-lodop/install_lodop64.exe' target='_self'>{{$t('i18nn_953f4232ebdf7eb2')}}</a>,安装后请刷新页面或重新进入。</font>";
						<br><font color='#FF00FF'>打印控件需要升级!点击这里<a href='/c-lodop/install_lodop64.exe' target='_self'>{{$t('i18nn_7fd5cb82192c32c2')}}</a>,升级后请重新进入。</font>";
						<br><br><font color='#FF00FF'>（注意：如曾安装过Lodop旧版附件npActiveXPLugin,请在【工具】->【附加组件】->【扩展】中先卸它）</font>";
						<br><br><font color='#FF00FF'>(如果此前正常，仅因浏览器升级或重安装而出问题，需重新执行以上安装）</font>";
						<br><font color='#FF00FF'>Web打印服务CLodop未安装启动，点击这里<a href='/c-lodop/CLodop_Setup_for_Win32NT.exe' target='_self'>{{$t('i18nn_e4772dc8568564ed')}}</a>";
						<br>（若此前已安装过，可<a href='CLodop.protocol:setup' target='_self'>{{$t('i18nn_07672c898e0f679f')}}</a>）";
						，成功后请刷新本页面。</font>";
						<br><font color='#FF00FF'>Web打印服务CLodop需升级!点击这里<a href='/c-lodop/CLodop_Setup_for_Win32NT.exe' target='_self'>{{$t('i18nn_7fd5cb82192c32c2')}}</a>,升级后请刷新页面。</font>"; -->
					</div>
					<!-- <div class="downList">
						<a href="http://localhost:8000" target="_blank">LODOP 本地预览配置页(http://localhost:8000)</a>
					</div> -->
					<!-- <div> -->
						<!-- <el-button @click="PRINT_SETUP">PRINT_SETUP</el-button> -->
						<!-- <el-button @click="PRINT_DESIGN">PRINT_DESIGN</el-button> -->
					<!-- </div> -->
					

					
				</div>
				
			</div>
		</div>






		
	</div>
</template>
<script>
import { priterPdfTest } from '@/utils/LodopPrinter.js';
export default {
	components: {
		
	},
	data() {
		return {
			// hasLODOP:false,
			// LODOP:{},
			version:'6.5.7.2',
			CLODOP:{},
		};
	},
	activated() {
		this.initData();
	},
	//创建时
	created() {
		this.initData();
	},
	//编译挂载前
	mounted() {
		
	},
	methods: {
		initData() {
			// try {
			//     let LODOP = getCLodop();
			// 		if(LODOP){
			// 			this.hasLODOP = true;
			// 			this.LODOP = LODOP;
			// 			console.log(this.LODOP);
			// 		}
			// } catch (err) {
			// 	console.log(err);
			// }
			try {
				this.CLODOP = CLODOP;
				}
			catch (err) {
				console.log(err);
			}
		},
		PRINT_SETUP(){
			let LODOP = getCLodop();
			LODOP.PRINT_SETUP();
		},
		PRINT_DESIGN(){
			let LODOP = getCLodop();
			LODOP.PRINT_DESIGN();
		},
		PRINT_PDF_TEST(){
			priterPdfTest();
		},
		
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.downList {
	padding: 10px;
	.title {
		font-size: 20px;
		line-height: 200%;
	}
	ul.list-style-1 {
		li {
			// list-style: disc inside;
			font-size: 14px;
			line-height: 200%;
		}
	}
	ul.list-style-2 {
		li {
			list-style: disc inside;
			font-size: 14px;
			line-height: 200%;
		}
	}
}
</style>
